<template>
  <div class="flightbooking_form_rightbody">
    <div class="heading">
      <h3>
        <div class="d-flex align-items-center">
          <span>
            <img src="/assets/sabre-img/information-hover1.png" alt="information-hover"/>
          </span>
          <strong>{{$t("sabre.booking.condition-box.condition-box-title")}} </strong>
        </div> {{$t('sabre.booking.condition-box.condition-notice')}}
      </h3>
    </div>
    <div class="flightbooking_form_middel">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="checked1" />
        <label class="form-check-label" for="flexCheckDefault"> ני מסכים לתנאי ההזמנה ותנאי דמי הביט <span>ול / שינוי הכלול בעסקה זו,</span> כמו גם שקראתי את מידע על <span>הנוסעים בנוגע להכנת טיסה - נגיף הקורונה.</span>
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2" v-model="checked2" />
        <label class="form-check-label" for="flexCheckDefault2"> מעוניין להוסיף פליינג סייף - "Flying Safe" בעלות 30$ לאדם <span>(תקנון Flying Safe)</span>
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3" v-model="checked3" />
        <label class="form-check-label" for="flexCheckDefault3"> מרצוני לקבל פרטים על מבצעים בדוא"ל. </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault4" v-model="checked4" />
        <label class="form-check-label" for="flexCheckDefault4"> מני מסכים למסור את פרטי להצעה טלפונית לביטוח נסיעות לחו”ל. </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'condition-form',
  data() {
    return {
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
    };
  },
  computed: {
    ...mapGetters({
      conditions: 'GET_SABRE_APPROVAL_CONDITION',
    }),
  },
  watch: {
    checked1() {
      this.update();
    },
    checked2() {
      this.update();
    },
    checked3() {
      this.update();
    },
    checked4() {
      this.update();
    },
  },
  mounted() {
    this.checked1 = (this.conditions && this.conditions[0]) || false;
    this.checked2 = (this.conditions && this.conditions[1]) || false;
    this.checked3 = (this.conditions && this.conditions[2]) || false;
    this.checked4 = (this.conditions && this.conditions[3]) || false;
  },
  methods: {
    update() {
      const { checked1, checked2, checked3, checked4 } = this;
      this.$emit('update', { type: 'condition', data: [checked1, checked2, checked3, checked4] });
    },
  },
};
</script>
